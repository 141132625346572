<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
            {{ $t("sidebar.inquiries Log") }}
          </h5>
        </v-col>
        <!-- inquiries Group -->
        <v-col cols="6">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{
                  $t("group.Inquiries Group")
                }}</v-toolbar-title>
                <button
                  class="py-5 mb-0 ms-auto"
                  v-if="
                    $store.getters['auth/str_per'].indexOf(
                      'inquiry-group-create'
                    ) > -1
                  "
                  @click="add_group()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <Table
                :loading="loading"
                @action="action"
                :btns="data_group"
                :headers="header_group"
                :items="groups"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
        <!-- inquiries list -->
        <v-col cols="6">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{
                  $t("sidebar.Inquiry List")
                }}</v-toolbar-title>
              </v-app-bar>
              <Table
                @action="action"
                :btns="btns"
                :headers="header"
                :items="inqieries"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
      <v-row> </v-row>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/Table.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("inquiry");
export default {
  name: "InquiryGroup-Page",
  components: {
    Table,
    Notify,
    Modal,
  },
  data() {
    return {
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      btns: [
        // {type:'icon',text : "edit_list",color:'bg-gradient-success',icon:'mdi-pencil'},
        // {type:'icon',text : "destroy_list",color:'bg-gradient-danger',icon:'mdi-delete'},
      ],
      data_group: [
        {
          type: "icon",
          text: "show_list",
          color: "bg-gradient-info",
          icon: "mdi-eye",
        },
        {
          type: "icon",
          text: "edit_group",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "inquiry-group-update",
        },
        {
          type: "icon",
          text: "destroy_group",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "inquiry-group-delete",
        },
      ],
      header: [
        {
          text: this.$i18n.t("inquiry.Inquery Text"),
          value: "inquery_lang",
          align: "center",
        },
        // { text: this.$i18n.t('general.Action'), value: 'btns' ,align: 'center'},
      ],
      header_group: [
        {
          text: "Name",
          value: "name",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      list_of_inqieries: [],
      list_of_inputs: [],
      form_data_group: {
        name: "",
        inqieries: [],
      },
      form_style_group: [
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("group.Name"),
          error: null,
          value_text: "name",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "select",
          type_select: "multiple",
          label: this.$i18n.t("inquiry.Inqieries"),
          title_select: "inquery",
          value_text: "inqieries",
          items: [],
          error: null,
          rules: [(v) => v.length > 0 || this.$i18n.t("form.Item is required")],
        },
      ],
    };
  },
  computed: {
    ...mapState(["groups", "inquiries_data", "inqieries"]),
  },
  methods: {
    ...mapActions(["getGroups", "getInqieries", "destroyList", "destroyGroup"]),
    get_groups() {
      this.getGroups().then(
        (response) => {
          this.list_of_inqieries = response.data.inqieries;
          this.list_of_inputs = response.data.inputs;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    action(id, name, item) {
      if (name == "destroy_list") {
        this.destroy_list(item);
      } else if (name == "destroy_group") {
        this.destroy_group(id);
      } else if (name == "edit_group") {
        this.edit_group(item);
      } else if (name == "show_list") {
        this.get_inquiry(id);
      }
    },
    get_inquiry(id) {
      this.getInqieries(id);
    },
    destroy_list(item) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyList({ id: item.id, group_id: item.pivot.group_id }).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
    add_group() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("group.New Group");
      this.$store.dispatch("form/setFormData", this.form_data_group);
      this.$store.dispatch("form/setData", this.form_style_group);
      this.$store.state.form.style_form[1].items = this.list_of_inqieries;
      this.$store.commit("form/INFO_CALL", {
        name: "inquiry/addGroup",
      });
    },
    edit_group(item) {
      console.log(item);
      this.modal_data.title =
        this.$i18n.t("group.Edit Group") + " # " + item.name;
      this.$store.dispatch("form/setData", this.form_style_group);
      this.$store.state.form.style_form[1].items = this.list_of_inqieries;
      this.form_data_group.name = item.name;
      for (let i = 0; i < item.inqieries.length; i++) {
        const element = item.inqieries[i];
        this.form_data_group.inqieries.push(element.id);
      }
      this.$store.dispatch("form/setFormData", this.form_data_group);
      this.$store.commit("form/SET_DIALOG", true);
      this.$store.commit("form/INFO_CALL", {
        name: "inquiry/UpdateGroup",
        id: item.id,
      });
    },
    destroy_group(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyGroup(id).then(
            (response) => {
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_groups();
    document.title = this.$i18n.t("group.Inquiries Group");
  },
};
</script>
